$primay-color: #08a7a9;
$black-color: #000;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  padding: 10px;
}

.header {
  font-size: 18px;
  color: $black-color;
  margin-bottom: 10px;
  .title {
    font-weight: bold;
  }
  .sub-title {
    display: flex;
    justify-content: space-between;
    .step {
      display: flex;
      hr {
        width: 10px;
        margin-top: 13px;
        border: 0;
        height: 0;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        &.active {
          background-color: $primay-color !important;
          border-top: 2px solid $primay-color;
        }
      }
      .item {
        margin: 0 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px $primay-color solid;
        border-radius: 50%;
        background-color: white;
        &.active {
          background-color: $primay-color !important;
          color: #fff;
        }
      }
    }
  }
}

.form-item {
  margin-bottom: 24px;
  color: $black-color;
}

.app-bar {
  height: 50px;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(5, 71, 108, 1),
    rgba(8, 167, 169, 1)
  );
  padding: 10px;
  .body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    width: 100%;
    height: 100%;
    .logo {
      width: 100px;
      height: 100%;
      padding: 3px;

      img {
        width: 100%;
      }
    }
  }
}

.icon {
  color: #fff !important;
  font-size: 18px;
}

.terms {
  color: $primay-color;
  margin: 0 5px;
}

.mr-5 {
  margin-right: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.custom-loading {
  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.text-center {
  text-align: center;
}

.policy {
  width: 100%;

  p {
    color: black;
  }

  ol {
    color: black;
    margin-left: 30px;
  }

  .content {
    text-indent: 30px;
  }

  .detail {
    color: black;
    div + div {
      margin-top: 5px;
    }
  }
}

.register {
  padding: 10px;
}

.location-report {
  padding: 10px;
}

.issues-report {
  padding: 10px;
}

.product-model {
  padding: 10px;
}
